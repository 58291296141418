import $ from './jquery'

/**
 * Submit form in ajax
 * @author Bertrand Zang
 * @param event
 */
function ajaxSubmitForm (event) {
    event.preventDefault()
    // ajax url
    let url = ''
    if ($(this).data('url')) {
        url = $(this).data('url')
    }
    // current form
    const form = $(this)
    // container into print result
    const container = $(this).find('.ajax-form-response')

    if (url) {
        $.ajax({
            url: url,
            type: 'POST',
            data: $(this).serialize(),
            success: function (data) {
                if (container) {
                    container.html(data)
                }
                if (form) {
                    form.find('button.btn').removeAttr('disabled')
                }
            },
            error: function (jqXHR, textStatus, errorThrow) {
                console.log('Ajax request - ' + textStatus + ': ' + errorThrow)
                if (form) {
                    form.find('button.btn').removeAttr('disabled')
                }
            }
        })
    }
}

$(() => {
    $('form.ajax-form').off('submit').on('submit', ajaxSubmitForm)
})
