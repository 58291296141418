import $ from '../../jquery'
import g_ from '../../adaptative'

$.id_listToDropdown = function (el, options) {
    const _this = this
    const $filters = $(el)
    this.$label = null
    this.bolIsMobileDisplay = false
    this.bolFiltersMenuOpen = false
    this.$mobileMenuFiltersTextHolder = null
    this.mobileMenuFiltersTextHolderText = ''
    this.init = function () {
        this.bolIsMobileDisplay = g_.isMobileDisplay
        this.options = $.extend({}, $.id_listToDropdown.defaultOptions, options)
        if (g_.isMobileDisplay) {
            this.setFiltersToMobile()
        }
        $(window).on('resize', () => {
            // manage ios safari bottom bar (open or not from scroll)
            document.body.height = window.innerHeight
            const winW = $(window).width()
            if (winW <= g_.mobileNavLimit && !this.bolIsMobileDisplay) {
                this.bolIsMobileDisplay = true
                this.setFiltersToMobile()
            } else if (winW > g_.mobileNavLimit && this.bolIsMobileDisplay) {
                this.bolIsMobileDisplay = false
                this.unsetFiltersToMobile()
            }
        })
    }
    /**
     *
     */
    this.attachEventsToFilters = function () {
        $('li', $filters).off('click').on('click', (e) => {
            this.mobileMenuFiltersTextHolderText = $(e.currentTarget).text()
            // update complete HTML to set chevron to new position based on text width
            this.$mobileMenuFiltersTextHolder.html('<span class="mobile-filters-label">' + this.mobileMenuFiltersTextHolderText + '</span>')
        })
    }
    /**
     * sets the list to dropdown on mobile
     */
    this.setFiltersToMobile = function () {
        // let's see if there is a selection made in case of URL redirect on click
        if ($('li a.active', $filters).length === 0) {
            // if not, take the first menu value and remove the entry from the menu to avoid double listing
            this.mobileMenuFiltersTextHolderText = $filters.find('li:first-child a').text()
            $filters.find('li:first-child').remove()
        } else {
            // if yes, take and remove it from the menu
            this.mobileMenuFiltersTextHolderText = $filters.find('li a.active').text()
            $filters.find('li a.active').parent().remove()
        }

        // insert mobile filters text holder
        if ($filters.parent().find('.mobile-filters-text-holder').length === 0) {
            this.$mobileMenuFiltersTextHolder = $('<div class="mobile-filters-text-holder"><span class="mobile-filters-label">' + this.mobileMenuFiltersTextHolderText + '</span></div>').insertBefore($filters)
        }
        // check if more than one option, don't add events if less
        if ($filters.children().length > 1) {
            $(document).keyup(function (e) {
                if (e.keyCode === 27) {
                    _this.toggleMobileFiltersMenu()
                }
            })
            // manage filters click on mobile
            this.$mobileMenuFiltersTextHolder.off('click').on('click', () => {
                this.toggleMobileFiltersMenu()
            })
            this.attachEventsToFilters()
        } else {
            // remove chevron if only one option
            this.$mobileMenuFiltersTextHolder.find('.mobile-filters-label').addClass('no-chevron')
        }
        $filters.parent().css({ position: 'relative' })
    }

    /**
     * unsets the list to dropdown on desktop
     */
    this.unsetFiltersToMobile = function () {
        if (this.$mobileMenuFiltersTextHolder.length) {
            this.$mobileMenuFiltersTextHolder.remove()
            $filters.show()
            $filters.removeAttr('style')
        }
    }

    /**
     * opens or closes the dropdown
     */
    this.toggleMobileFiltersMenu = function () {
        if (!this.bolFiltersMenuOpen) {
            // open menu
            $filters.addClass('open')

            $(document).mouseup((e) => {
                if (!$filters.is(e.target) && $filters.has(e.target).length === 0 && !this.$mobileMenuFiltersTextHolder.is(e.target) && this.$mobileMenuFiltersTextHolder.has(e.target).length === 0) {
                    this.toggleMobileFiltersMenu()
                    e.stopPropagation()
                }
            })
            this.bolFiltersMenuOpen = true
        } else {
            $(document).unbind('mouseup')
            $filters.removeClass('open')
            this.bolFiltersMenuOpen = false
        }
    }
    this.init()
}

window.$.id_listToDropdown.defaultOptions = {
}

window.$.fn.id_listToDropdown = function (options) {
    return this.each(function () {
        // eslint-disable-next-line
        (new $.id_listToDropdown(this, options))
    })
}
