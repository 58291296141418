// Force jQuery to be loaded onto the window scope at least once
import $ from './jquery'

// each plugin has a readme.md file in its folder. Read it.

import './plugins/id_list-to-dropdown/id_listToDropdown'

$(() => {
    // if ($('input[type="checkbox"]').length) {
    //     $('input[type="checkbox"]').id_radiocheckbox({
    //         multiple: true,
    //         floatingLabel: true,
    //         labelExtraPaddingLeft: 15,
    //         labelExtraLineHeight: 6,
    //         checkedStyle: 'checkmark'
    //     })
    // }
    if ($('.nav-pills').length) {
        $('.nav-pills').id_listToDropdown()
    }
})
