import $ from './jquery'
import g_ from './adaptative'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import jQueryBridget from 'jquery-bridget'
const smallNavTopLimit = 50
let isFixedNavigation = false
const $itemsToOpenOnOpenMenu = $('body, .burger-navbar-toggler, #mainnavigation, #page-header')

/**
 * check scroll position to resize nav
 */
function checkScroll () {
    const scrollT = $(window).scrollTop()
    if (scrollT >= smallNavTopLimit && !isFixedNavigation) {
        $('#page-header').addClass('smallNav')
        isFixedNavigation = true
    } else if (scrollT < smallNavTopLimit && isFixedNavigation) {
        $('#page-header').removeClass('smallNav')
        isFixedNavigation = false
    }
    if (!g_.isMobileDisplay) {
        if ($('.nav-pills').length) {
            const ptop = $('#pill-content').offset().top - parseInt($('#pill-content').css('top')) - $('#page-header').height()
            if (ptop <= scrollT) {
                if (!$('.nav-pills').hasClass('sticked')) {
                    $('.nav-pills').addClass('sticked')
                    $('.nav-pills').css('top', $('#page-header').height())
                }
            } else {
                if ($('.nav-pills').hasClass('sticked')) {
                    $('.nav-pills').removeClass('sticked')
                }
            }
        }
    }
}
$(() => {
    checkScroll()
    $(window).scroll(function () {
        checkScroll()
    })
    if (g_.isMobileDisplay) {
        $('.burger-navbar-toggler').off('click').on('click', (e) => {
            e.preventDefault()
            $itemsToOpenOnOpenMenu.toggleClass('open')
        })
    }
    if ($('.frame-background-light').length) {
        $('.frame-background-light').parents($('#page-content')).addClass('inverted')
    }
    if ($('.frame-background-dark').length) {
        $('.frame-background-dark').parents($('#page-content')).addClass('special-order')
    }
    if ($('.masonry').length) {
        jQueryBridget('masonry', Masonry, $)
        jQueryBridget('imagesLoaded', imagesLoaded, $)
        // eslint-disable-next-line no-unused-vars
        const $wrapper = $('.masonry')
        $wrapper.imagesLoaded(() => {
            $wrapper.masonry({
                itemSelector: '.resource-card'
            })
        })
    }
    if ($('.modal-opener').length) {
        $.fn.modal.Constructor.prototype._enforceFocus = function () {}
        $('.modal-opener').off('click').on('click', (e) => {
            e.preventDefault()
            const target = $(e.currentTarget).data('target')
            $('#' + target).modal()
            $('.modal-closer', $('#' + target)).off('click').on('click', (e) => {
                e.preventDefault()
                $('#' + target).modal('hide')
            })
        })
    }
    if ($('.nav-pills').length) {
        $('.nav-pills').parent().css('min-height', '60px')
        $('.nav-pills a').each((key, item) => {
            $(item).attr('href', $(item).attr('href') + '#pill-content')
        })
    }
})
