/*!
 * CdT Package
 * Manages display and devices
 *
 */

import $ from './jquery'
import UAParser from 'ua-parser-js'
const Flickity = require('flickity')

// global vars exported inside g_ object
const g_ = {
    // mobile navigation width breakpoint
    mobileNavLimit: 992,

    // Exposing global vars
    isMobileDisplay: false,
    isMobile: false,
    isTouch: false,
    isTouchDeviceOnDesktopNav: false,
    wasInitialized: false,
    isIphone: null,
    mobileMenuOpen: false,
    menuLang: null
}

let newSlider = null

/**
 * set events to Flickity for mobile
 */
function flickityNews () {
    if ($('.backendlayout-home').length) {
        newSlider = new Flickity('.news-list-view', {
            cellAlign: 'left',
            prevNextButtons: false,
            // autoPlay: 5000,
            wrapAround: false,
            pageDots: false,
            cellSelector: '.news-card',
            groupCells: '80%',
            dragThreshold: 10
        })
    }
}

/**
 * destroys flickity when going desktop
 */
function unflickityNews () {
    if (newSlider) {
        newSlider.destroy()
        newSlider = null
    }
}

/**
 * mobile display
 */
function setMobile () {
    g_.isMobileDisplay = true
    document.body.height = window.innerHeight
    flickityNews()
}

/**
 * desktop display
 */
function setDesktop () {
    g_.isMobileDisplay = false
    unflickityNews()
}

/**
 *  Sets mobile or desktop display on resize or first load
 */
function setDisplayType () {
    // manage ios safari bottom bar (open or not from scroll)
    const winW = $(window).width()
    if (winW <= g_.mobileNavLimit && !g_.isMobileDisplay) {
        setMobile()
    } else if (winW > g_.mobileNavLimit && g_.isMobileDisplay) {
        setDesktop()
    }
    g_.wasInitialized = true
}

$(() => {
    /**
     * get device type
     */
    $('body').removeAttr('style')
    const parser = new UAParser()
    const isTouchDevice = 'ontouchstart' in document.documentElement
    g_.isMobile = parser.getDevice().type === 'mobile' || ((parser.getDevice().type === 'tablet') && $(window).width() <= g_.mobileNavLimit)
    // mode menu desktop sur touch device
    if ((parser.getDevice().type === 'tablet' || isTouchDevice) && $(window).width() > g_.mobileNavLimit) {
        g_.isTouchDeviceOnDesktopNav = true
    }
    if (g_.isMobile || g_.isTouchDeviceOnDesktopNav) g_.isTouch = true
    g_.fimMenu = $('#fim-menu').clone()
    // g_.menuLang = $('#lang-menu').clone()

    setDisplayType()
    $(window).on('resize', function () {
        setDisplayType()
    })
})
export default g_
