// Import polyfills for old browsers (cf package.json 'browserslist')
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Force jQuery to be loaded onto the window scope at least once
import $ from './jquery'

// Import bootstrap package things
import './bootstrap_package'

// Our pretty console decorator
import './console_decorator'

// Import main SCSS theme
import '../Scss/theme.scss'

// Import some modules
// Everything that should be loaded to the whole site should be placed here
import './adaptative'
import './app'
// Font awesome (uncomment to use)
// import './components/icons'

// Form behaviors
// prevent double submit (activated by default)
import './plugins/id_preventFrmDblSubmit/id_preventFrmDblSubmit'

import './form'

// jQuery Ideative plugins (SBA), uncomment to use, customize in plugins.js. Documentation available in each plugin
// demo on demo page (https://t3starterpackage-master.ideative.io/fr/demo)
import './plugins'

// Main entry function (when DOM loaded)
$(function () {
    // Asynchronously load flickity if nweeded
    if ($('[data-flickity], .flickity, .mobile-slider').length) {
        import('./components/flickity')
    }

    // Asynchronously load lightbox if needed
    if ($('a.lightbox').length) {
        import('./components/lightbox')
    }

    // init prevent double submit
    $('form').id_preventFrmDblSubmit({})

    // add rel attribute to target blank link
    $("a[target='_blank']").each(function () {
        $(this).attr('rel', 'noopener noreferrer')
    })
})

/*
 * Important : to load jQuery, we need to import it from the custom module in
 * packages/cdt_package/Resources/Private/Assets/JavaScript/jquery.js
 *
 * The correct synthax is (replacing the relative path portion):
 * import $ from './jquery'
 *
 * This allows us to add plugins, inject some things and be sure that everywhere it is loaded, it's the right instance
 */
